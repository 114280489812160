import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInlineDefinition,
  CHeroSlider,
  CHeroSlider03,
  CDefinition,
  CShopInfo,
  CGrandMenu,
  CShopPrivate,
  CRestaurantPlanRecommend,
  CRestaurantJumbotron,
  CBtnList,
  LContact,
  LContact02,
  LContact03,
  LStores,
  CVariableSlider,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import restaurantPlanChoice from '../../../utils/restaurantPlan-choice';
import restaurantPlanGet from '../../../utils/restaurantPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const shopdata = shopGet('symphony');

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        data={{
          logo: {
            src: '/assets/images/restaurants/symphony/symphony_logo.svg',
            alt: '',
            widthSp: '160px',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/symphony/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/symphony/kv__sp.png',
              },
            },
          ],
        }}
      ></CRestaurantJumbotron>
      <LContact02
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-symphony/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
      />
      <div className="u_pt100 u_mbLarge" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CHeroSlider03
            exClass="slick-aspect-1"
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/symphony/img_symphony.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/symphony/img_symphony__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/symphony/img_symphony02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/symphony/img_symphony02__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/symphony/img_symphony03.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/symphony/img_symphony03__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/symphony/img_symphony04.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/symphony/img_symphony04__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/symphony/img_symphony05.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/symphony/img_symphony05__sp.png',
                    alt: '',
                  },
                },
              ],
              title: (
                <>シェフの料理と笑顔が交響曲のように広がるくつろぎの空間</>
              ),
              text: (
                <>
                  大きな窓から外光が差し込む開放的な雰囲気。テーブル席や、爽やかに流れる滝や水面を臨むカウンター席、
                  <br className="u_pc" />
                  ゆったりと落ち着けるソファ席で、シェフがお届けする親しみやすいお料理をお楽しみください。
                </>
              ),
            }}
          />
          <CHeroSlider03
            exClass="slick-aspect-1 u_mb100"
            reverse={true}
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/symphony/img_symphony06.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/symphony/img_symphony06__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/symphony/img_symphony07.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/symphony/img_symphony07__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/symphony/img_symphony08.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/symphony/img_symphony08__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/symphony/img_symphony09.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/symphony/img_symphony09__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/symphony/img_symphony10.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/symphony/img_symphony10__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/symphony/img_symphony11.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/symphony/img_symphony11__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/symphony/img_symphony12.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/symphony/img_symphony12__sp.png',
                    alt: '',
                  },
                },
              ],
              text: (
                <>
                  幅広い層のお客様に人気のブッフェは、朝食、ランチ、土・日・祝日のディナーにバラエティに富んだラインナップでご用意。メインディッシュをお選びいただけるコースメニューやアラカルトメニューも充実しております。
                </>
              ),
            }}
          />
          <CSectTitle
            title={{
              en: 'INFORMATION',
              ja: '店舗情報',
            }}
          />
          <p className="u_tac_sp">
            最新の営業時間は、<a href="/information/timeindex/">こちら</a>
            をご覧ください。
          </p>
          <CShopInfo data={shopdata} exClass="u_mt20" />
        </LWrap>
      </div>
      <section className="l_sect u_bgGray" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CSectTitle
            title={{
              en: 'MENU',
              ja: 'おすすめメニュー',
            }}
          />
          <CRestaurantPlanRecommend
            data={restaurantPlanChoice(
              restaurantPlanGet(),
              ['シェフズダイニング シンフォニー'],
              0
            )}
          />
          <CBtnList
            data={[
              {
                label: '他のプランを見る',
                link: {
                  href: '/restaurants/plan/',
                },
                color: 'bgWhite03',
              },
            ]}
          />
          <CGrandMenu data={shopdata} exClass="u_mt70 u_mb20" />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン&バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-symphony/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          subText: ' （代表） ',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
        linkList={[
          {
            label: 'よくあるご質問',
            link: {
              href: '/faq/restaurants/',
            },
          },
          {
            label: '食物アレルギーをお持ちの方へ',
            link: {
              href: '/restaurants/allergy/',
            },
          },
        ]}
      />
      <LStores />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
